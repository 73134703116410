<template>
  <cashiers-limits-view />
</template>

<script>
import CashiersLimitsView from './components/CashiersLimitsView.vue'

export default {
  components: {
    CashiersLimitsView,
  },
}
</script>
